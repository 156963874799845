import { useStores } from "../../stores";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useState } from "react";

type Imodal = {
  calldelete: Function;
  handleClose: Function;
  notification: boolean;
  message: string;
  modalType: string;
  header: any;
};

export const Modal = (props: Imodal) => {
  const styles = {
    modal: { display: "block", opacity: 1, backgroundColor: "rgba(0,0,0,0.5)" },
    border: { border: "1px solid black" },
  } as const;

  const handleDelete = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.calldelete();
  };
  const handleClose = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.handleClose();
  };

  // if (
  //   props.notification === true &&
  //   props.type !== "CampaignManage" &&
  //   props.type !== "CampaignTimestamps"
  // ) {
  return (
    <div
      className="modal fade warning"
      id="Modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={styles.modal}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                handleClose(e);
              }}
            ></button>
            <h3>{props.header}</h3>
            <h5>{props.message}</h5>
          </div>
          <div className="modal-footer">
            {(() => {
              if (props.modalType === "DeleteErrorPopUp") {
                return (
                  <button
                    type="button"
                    className="btn btn-secondary small outline"
                    data-dismiss="modal"
                    style={{ fontSize: "17px" }}
                    onClick={(e) => {
                      handleClose(e);
                    }}
                  >
                    Okay
                  </button>
                );
              } else if (
                props.modalType === "DeleteConfirmPopUp" ||
                "DeleteAllConfirmPopUp"
              ) {
                return (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary small"
                      onClick={(e) => {
                        handleDelete(e);
                      }}
                    >
                      {props.modalType === "DeleteAllConfirmPopUp"
                        ? "Delete"
                        : "Yes, Delete"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary small outline"
                      data-dismiss="modal"
                      onClick={(e) => {
                        handleClose(e);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                );
              } else if (props.modalType === "ResendConfirmPopUp") {
                return (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary small"
                      onClick={(e) => {
                        handleDelete(e);
                      }}
                    >
                      Resend
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary small outline"
                      data-dismiss="modal"
                      onClick={(e) => {
                        handleClose(e);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div
  //       className="modal fade warning"
  //       id="deleteModal"
  //       tabIndex={-1}
  //       role="dialog"
  //       aria-labelledby="exampleModalLabel"
  //       aria-hidden="true"
  //       style={styles.modal}
  //     >
  //       <div className="modal-dialog modal-dialog-centered" role="document">
  //         <div className="modal-content">
  //           <div className="modal-body">
  //             <button
  //               type="button"
  //               className="close"
  //               data-dismiss="modal"
  //               aria-label="Close"
  //               onClick={(e) => {
  //                 handleClose(e);
  //               }}
  //             >
  //               <span aria-hidden="true">&times;</span>
  //             </button>
  //             <h3>Delete?</h3>
  //             <p>Are you sure you want to delete this file?</p>
  //           </div>
  //           <div className="modal-footer">
  //             <button
  //               type="button"
  //               className="btn btn-primary small"
  //               onClick={(e) => {
  //                 handleDelete(e);
  //               }}
  //             >
  //               Delete
  //             </button>
  //             <button
  //               type="button"
  //               className="btn btn-secondary small outline"
  //               data-dismiss="modal"
  //               onClick={(e) => {
  //                 handleClose(e);
  //               }}
  //             >
  //               Cancel
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
};
